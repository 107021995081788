import styled from '@emotion/styled';

const BottomBackground = styled.div`
  position: absolute;
  height: 1119px;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: -100;
  overflow: hidden;
  background-image: linear-gradient(to bottom right, #f1f8fb, #f2f3fa, #f6e1f6);

  & > div {
    position: absolute;
    top: 0;
    border-left: 100vw solid white;
    border-bottom: 41px solid transparent;
    height: 447px;
  }
  @media (min-width: 768px) {
    & > div {
      border-bottom: 65px solid transparent;
    }
  }

  @media (min-width: 992px) {
    background-image: linear-gradient(
      to bottom right,
      #f1f8fb,
      #f2f3fa,
      #f6e1f6
    );

    & > div {
      border-bottom: 95px solid transparent;
    }
  }

  @media (min-width: 1200px) {
    & > div {
      border-bottom: 115px solid transparent;
    }
  }
`;

const BottomBackgroundShape = () => (
  <BottomBackground>
    <div />
  </BottomBackground>
);

export default BottomBackgroundShape;
