import styled from '@emotion/styled';

const TopBackground = styled.div`
  position: absolute;
  height: 1716px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -100;
  overflow: hidden;
  background-image: linear-gradient(to bottom right, #f1f8fb, #f2f3fa, #f6e1f6);

  & > div {
    position: absolute;
    bottom: 0;
    border-right: 100vw solid white;
    border-top: 41px solid transparent;
    height: 447px;
  }
  @media (min-width: 768px) {
    & > div {
      border-top: 65px solid transparent;
    }
  }

  @media (min-width: 992px) {
    background-image: linear-gradient(
      to bottom right,
      #f1f8fb,
      #f2f3fa,
      #f6e1f6
    );

    & > div {
      border-top: 95px solid transparent;
    }
  }

  @media (min-width: 1200px) {
    & > div {
      border-top: 115px solid transparent;
    }
  }
`;

const TopBackgroundShape = () => (
  <TopBackground>
    <div />
  </TopBackground>
);

export default TopBackgroundShape;
