import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import SlicesRenderer from '../components/SlicesRenderer';
import { Container } from '../components/elements';

import TopBackgroundShape from '../components/TopBackgroundShape';
import BottomBackgroundShape from '../components/BottomBackgroundShape';

const PageBody = ({ document }) => (
  <>
    <SlicesRenderer slices={document.body} />
  </>
);

PageBody.propTypes = {
  document: PropTypes.object.isRequired
};

const HomeTemplate = ({ data }) => {
  const {
    prismicLayout: { data: layout },
    prismicHome: { data: page }
  } = data;
  const {
    top_background: topBackground,
    bottom_background: bottomBackground
  } = page;

  if (!layout || !page) return null;

  return (
    <Layout layout={layout} siteMetadata={data.site.siteMetadata}>
      <TopBackgroundShape />
      <Container width="100%" pt={170} px="15px" style={{ overflow: 'hidden' }}>
        <PageBody document={page} />
      </Container>
      <BottomBackgroundShape />
    </Layout>
  );
};

HomeTemplate.propTypes = {
  data: PropTypes.object.isRequired
};

export default HomeTemplate;

export const data = graphql`
  query {
    ...LayoutFragment
    site {
      siteMetadata {
        title
        author
      }
    }
    prismicHome {
      data {
        body {
          ... on PrismicHomeBodyHeader {
            id
            slice_type
            primary {
              logo_img {
                alt
                url
              }
              navigation {
                document {
                  data {
                    body {
                      primary {
                        link
                        name
                      }
                    }
                  }
                }
              }
              header_cta {
                document {
                  data {
                    link
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomeBodyHero {
            id
            slice_type
            primary {
              title
              description
              image {
                alt
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 793, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomeBodyProducts {
            id
            slice_type
            primary {
              title
            }
            items {
              title
              image {
                alt
                localFile {
                  url
                }
              }
              cta {
                document {
                  data {
                    link
                    title {
                      text
                    }
                  }
                }
              }
              description
            }
          }
          ... on PrismicHomeBodyFeatured {
            id
            slice_type
            primary {
              featured_project {
                document {
                  data {
                    pre_heading
                    title
                    description
                    body {
                      primary {
                        title_plugins
                      }
                      items {
                        plugin_image {
                          alt
                          url
                        }
                      }
                    }
                    image {
                      alt
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 642, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                          }
                        }
                      }
                    }
                    project_cta {
                      document {
                        data {
                          description
                          link
                          title {
                            text
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomeBodyFeatures {
            id
            slice_type
            primary {
              heading
              pre_heading
            }
            items {
              title
              description
              icon {
                alt
                url
              }
            }
          }
          ... on PrismicHomeBodyWorkflow {
            id
            slice_type
            primary {
              heading
              pre_heading
            }
            items {
              title
              description
              icon {
                alt
                url
              }
            }
          }
          ... on PrismicHomeBodyTechStack {
            id
            slice_type
            primary {
              heading
              pre_heading
            }
            items {
              tech_stack_card {
                document {
                  data {
                    title
                    body {
                      items {
                        gallery_image {
                          alt
                          url
                        }
                        website_url
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomeBodyCta {
            id
            slice_type
            primary {
              title
              description
              cta {
                document {
                  data {
                    link
                    description
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomeBodyFooter {
            id
            slice_type
            items {
              link {
                url
                target
              }
              social_logo {
                url
                alt
              }
              title
              aria_label
            }
            primary {
              logo {
                url
                alt
              }
              copyright
            }
          }
        }
      }
    }
  }
`;
